// gatsby-browser.js

// Import your main stylesheet here (the path might vary depending on your file structure)
import "./src/styles/global.css";

// Here you can also include other global JavaScript files or libraries if needed.

// Example of a Gatsby browser API usage
export const onRouteUpdate = ({ location, prevLocation }) => {
    console.log('New pathname:', location.pathname);
    // You can perform actions on route change here, like tracking page views
};

// More Gatsby browser APIs can be added as needed.
